@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --radix-connect-button-border-radius: 100px;
}

#background {
  background-image: url("./assets/images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

radix-connect-button {
  display: flex;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="range"]::-webkit-slider-thumb {
  cursor: pointer;
  width: 17px;
  height: 17px;
  border-radius: 100%;
  -webkit-appearance: none;
  border: 3px solid #2d92ef;
  background: #423c3c;
  transition: 250ms all;
}

input[type="range"]:hover::-webkit-slider-thumb {
  transform: scale(1.25);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
